@import '../../index.scss';

.__gallery {
	position: fixed;
	top: 0;
	right: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(43, 43, 43, 0.9);
	z-index: 10;

	#gallery__cross {
		cursor: pointer;
		position: absolute;
		right: 30px;
		top: 30px;
		width: 20px;
		height: 20px;
		z-index: 11;
	}

	.gallery__images {
		display: flex;
		overflow: scroll;
		position: absolute;
		width: 100%;
		height: calc(100% - 60px);
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;
		@include hideBars();

		.image-description {
			min-width: 100vw;
			height: 100%;
			padding: 0 100px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			box-sizing: border-box;
			scroll-snap-align: center;
			position: relative;
		}
		.image-description__image {
			object-fit: contain;
			object-position: center;
			max-height: 80%;
			z-index: 11;
			pointer-events: none;
		}
		.description {
			font-size: 20px;
			color: white;
			text-align: center;
			margin-top: 10px;
			z-index: 12;
		}
	}
	.gallery__images-image {
		min-width: 100vw;
		padding: 0 100px;
		box-sizing: border-box;
		scroll-snap-align: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: scale-down;
			object-position: center;
			z-index: 11;
			pointer-events: none;
		}
	}
	.gallery__arrows {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		padding: 20px;
		pointer-events: none;
		img {
			width: 30px;
			height: 30px;
			pointer-events: all;
			z-index: 11;
			cursor: pointer;
		}
	}
	.gallery__index {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		gap: 20px;
		position: absolute;
		bottom: 15px;
		right: 50%;
		transform: translateX(50%);
		background: rgba(0, 0, 0, 0.5);
		border-radius: 4px;
		padding: 6px 20px;
		h3 {
			color: white !important;
		}
		svg {
			cursor: pointer;
		}
	}
}
@media screen and (max-width: $breakpoint-ipad) {
	.__gallery {
		left: 0;
		#gallery__cross {
			right: 20px;
			top: 43px;
		}
		.gallery__images {
			.image-description {
				padding: 0 16px;

				.image-description__image {
					object-fit: contain;
					border: none;
				}
				.description {
					font-size: 12px;
					margin-top: 16px;
				}
			}
			.gallery__images-image {
				padding: 0 16px;

				img {
					object-fit: contain;
					border: none;
				}
			}
		}
		.gallery__index {
			bottom: 50px;
		}
	}
}
