.image-slider__container {
	// height: 1024px;
	width: 100vw;
	position: relative;
	top: 0;
	left: 0;
	.slider {
		z-index: 1;
	}
	.image-slider__image {
		object-fit: cover;
		width: 100vw;
		height: 800px;
		object-position: center;
		clip-path: polygon(0% 0%, 100% 0, 100% 76%, 50% 94%, 0 76%);
		filter: brightness(35%);
	}
	@media screen and (max-width: 764px) {
		.image-slider__image {
			height: 700px;
		}
	}
}
