.photo-grid__wrapper {
	display: grid;
	width: 100%;
	height: 25vw;
	max-height: 400px;
	grid-template-columns: 2fr 1.1fr;
	grid-template-rows: repeat(2, minmax(0, 185px));
	grid-gap: 20px;
	position: relative;

	.photo {
		border-radius: 4px;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: filter 0.3s ease-in-out;
		cursor: pointer;
		&:hover {
			filter: brightness(80%);
		}
	}

	.photo:nth-child(1) {
		grid-row: 1/3;
		grid-column: 1/2;
	}
	.photo:nth-child(3) {
		grid-row: 1/2;
		grid-column: 2/3;
	}
	.photo:nth-child(2) {
		grid-row: 2/3;
		grid-column: 2/3;
	}

	button {
		box-sizing: border-box;
		font-weight: 600;
		font-size: 14px;
		color: #3564e2;
		padding: 13px;
		background: #ffffff;
		border: 1px solid #f2f2f2;
		box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.4);
		border-radius: 4px;
		position: absolute;
		bottom: 20px;
		right: 36px;
		cursor: pointer;

		@media screen and (max-width: 764px) {
			right: 50%;
			bottom: -70px;
			transform: translateX(50%);
		}
	}
	@media screen and (max-width: 764px) {
		grid-gap: 8px;
		height: 140px;
	}
}
