.app {
	display: flex;
	flex-direction: column;

	.top {
		transition: transform 0.9s;
		transform: translateY(0);
		width: 100vw;
	}

	.sections {
		font-family: poppins;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 40px;
		width: 100%;
		section {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 40px 0px;
			h3 {
				font-weight: 400;
				font-size: 32px;
				margin-bottom: 40px;
			}
			.brands {
				display: flex;
				flex-wrap: wrap;
				padding: 0 80px;
				justify-content: center;
				img {
					height: 100px;
					width: 270px;
					object-fit: contain;
					padding: 30px 40px;
					border: 1px solid #d8d8d8;
				}
			}
			.gallery {
				width: 100%;
				padding: 0 80px;
				margin-bottom: 40px;
			}
			.contact {
				display: flex;
				flex-direction: column;
				gap: 40px;

				.contact-details {
					display: flex;
					justify-content: space-around;

					.detail {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 4px;
						width: 260px;

						img {
							width: 24px;
						}
						.detailText {
							text-align: center;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 764px) {
	.app {
		.sections {
			padding: 0 20px;
			section {
				padding-top: 0;
				padding-bottom: 80px;
				.contact {
					.contact-details {
						flex-direction: column;
						align-items: center;
						gap: 40px;
					}
					.map {
						max-width: 100%;
					}
				}
				.brands {
					padding: 0;
					img {
						padding: 20px;
					}
				}
				.gallery {
					padding: 0;
				}
			}
		}
	}
}
