body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

// Colors
$clr-primary-400: #ffffff;
$clr-neutral-900: #000000;

$btn-clr-500: #3564e2;
$btn-clr-400: #1b4aca;

$text-clr-900: #333333;
$text-clr-600: #666666;

$brd-clr: #dddddd;
$brd-clr-blue: #3564e2;

$button-background-gradient: linear-gradient(
	97.31deg,
	#609cff 1.48%,
	#6e61ff 99.05%
);

// Breakpoints
$breakpoint-mobile: 767px;
$breakpoint-ipad: 900px;
$ultrawide-max-width: 1650px;

// Mixins
@mixin hideBars {
	overflow-x: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}
@mixin blue-thumb-scrollbar {
	&::-webkit-scrollbar {
		height: 2px;
		width: 2px;
	}
	&::-webkit-scrollbar-track {
		background-color: #dddddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $btn-clr-500;
	}
}
@mixin full-width($horizontalPadding: 16px, $position: relative) {
	position: $position;
	margin-left: -$horizontalPadding;
	padding: 0 $horizontalPadding;
	min-width: calc(100% + #{$horizontalPadding} * 2);
}
//typography
.clr-blue {
	color: $brd-clr-blue !important;
}
.clr-grey {
	color: $text-clr-600 !important;
}
.clr-black {
	color: $text-clr-900 !important;
}
.clr-white {
	color: white !important;
}

.text-12px-light {
	font-family: var(--ff-body);
	font-weight: 500;
	font-size: 12px;
}
.text-12px {
	font-family: var(--ff-body);
	font-weight: 600;
	font-size: 12px;
}
.text-12px-bold {
	font-family: var(--ff-body);
	font-weight: 700;
	font-size: 12px;
}
.text-xsm-light {
	font-family: var(--ff-body);
	font-weight: 500;
	font-size: 14px;
}
.text-xsm {
	font-family: var(--ff-body);
	font-weight: 600;
	font-size: 14px;
	line-height: 150%;
}
.text-xsm-bold {
	font-family: var(--ff-body);
	font-weight: 700;
	font-size: 14px;
}
.text-sm-light {
	font-family: var(--ff-body);
	font-weight: 500;
	font-size: 16px;
}
.text-sm {
	font-family: var(--ff-body);
	font-weight: 600;
	font-size: 16px;
}
.text-sm-bold {
	font-family: var(--ff-body);
	font-weight: 700;
	font-size: 16px;
}
.text-sm-bold-ex {
	font-family: var(--ff-body);
	font-weight: 700;
	font-size: 16px;
}
h2 {
	font-family: var(--ff-body);
	font-weight: 800;
	font-size: 30px;
	line-height: 150%;
	color: #333333;
}
h3 {
	font-family: var(--ff-body);
	font-size: 24px;
	font-weight: bold;
	color: #333333;
}

.sub-heading-light {
	font-family: var(--ff-body);
	font-weight: 600;
	font-size: 20px;
	color: #333333;
}
.sub-heading {
	font-family: var(--ff-body);
	font-weight: 600;
	font-size: 20px;
	color: #333333;
}

.sub-heading-bold {
	font-family: var(--ff-body);
	font-weight: 700;
	font-size: 20px;
	color: #333333;
}

// listings
@media screen and (max-width: $breakpoint-mobile) {
	.listing__outer-wrapper {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 12px;
		}
		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}
		h2 {
			font-size: 24px;
		}
		h3 {
			font-size: 18px;
		}
		.sub-heading,
		.sub-heading-light,
		.sub-heading-bold {
			font-size: 16px;
		}
	}
}

// booking price component
@media screen and (max-width: $breakpoint-mobile) {
	.booking__price {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 14px;
		}
		.text-12px {
			font-weight: 600;
			font-size: 10px;
		}
		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}
		h2 {
			font-size: 24px;
		}
		h3 {
			font-size: 20px;
		}
		.sub-heading {
			font-size: 16px;
		}
		.sub-heading-bold {
			font-size: 16px;
		}
		.sub-heading-light {
			font-size: 16px;
			font-weight: 500;
		}
	}
}

// booking detail component
@media screen and (max-width: $breakpoint-mobile) {
	.bookingphone__details-detail {
		.text-xsm,
		.text-xsm-light,
		.text-xsm-bold {
			font-size: 14px;
		}
		.text-12px {
			font-weight: 600;
			font-size: 10px;
		}
		.text-sm,
		.text-sm-light,
		.text-sm-bold {
			font-size: 14px;
		}
		h2 {
			font-size: 24px;
		}
		h3 {
			font-size: 20px;
		}
		.sub-heading {
			font-size: 16px;
		}
		.sub-heading-bold {
			font-size: 16px;
		}
		.sub-heading-light {
			font-size: 16px;
			font-weight: 500;
		}
	}
}
