.hero-section {
	display: flex;
	align-items: center;
	flex-direction: column;

	position: absolute;
	top: 0;
	left: 0;
	// height: 1024px;
	width: 100%;
	padding: 20px;

	z-index: 2;

	header {
		width: 100%;
		max-width: 1200px;
		display: flex;
		justify-content: space-between;
		margin-top: 60px;
		align-items: center;
		.logo {
			height: 94px;
			transform: rotateZ(0deg);
		}

		h2 {
			font-family: 'Nunito', sans-serif;
			color: white;
		}

		.info-container {
			display: flex;
			font-size: 14px;
			gap: 16px;
			.info {
				display: flex;
				color: white;
				align-items: center;
				gap: 8px;
				img {
					width: 30px;
				}
			}
		}
	}

	h1 {
		font-family: 'Kanit', sans-serif;
		color: white;

		font-size: 74px;
		letter-spacing: 5px;
		margin-top: 120px;
		text-align: center;
	}
	.slogan {
		font-size: 22px;
		color: white;
		margin-top: 20px;
		letter-spacing: 1px;
		font-family: 'Kanit', sans-serif;
		text-align: center;

		.highlight {
			color: #2091f9;
			font-size: 28px;
			font-style: italic;
		}
	}

	.actions {
		margin-top: 100px;
		display: flex;
		width: 100%;
		justify-content: center;
		gap: 40px;

		.form-button {
			max-height: max-content;

			font-family: poppins;
			max-width: 200px !important;
			font-weight: 200;
			border-radius: 40px;
			padding: 4px 24px;
		}
	}
}
@media screen and (max-width: 764px) {
	.hero-section {
		h1 {
			font-size: 48px;
			margin-top: 100px;
		}
		.slogan {
			font-size: 14px;
		}
		header {
			margin-top: 20px;
			.logo {
				height: 74px;
			}
			.info-container {
				font-size: 10px;
				flex-direction: column;
				gap: 12px;
				.info {
					flex-direction: row-reverse;
					text-align: right;
					img {
						width: 18px;
					}
				}
			}
		}
		.actions {
			.form-button {
				font-family: poppins;
				max-width: 140px !important;
				font-weight: 200;
				border-radius: 40px;
				padding: 4px 24px;
				font-size: 12px !important;
			}
		}
	}
}
