.form-button {
	width: 100%;
	// height: 45.25px;
	background: #2694f7;
	border: 1px solid #2694f7;
	border-radius: 3.8345px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	// border: none;
	color: #ffffff;
	cursor: pointer;
	padding: 8px 12px;
	max-height: max-content;
	max-width: max-content;
	min-width: 40px;
	margin-top: -4px;
	display: flex;
	justify-content: center;
	align-items: center;
	&.danger {
		background: #ff0000;
		border: 1px solid #ff0000;
	}
	&.form-button-loading {
		min-width: 70px;
	}
	transition: filter 0.2s ease-in-out;

	&.form-bottom__transparent {
		background: transparent !important;
		color: #2694f7;
		font-weight: 400;

		&:disabled {
			cursor: not-allowed;
			border-color: grey;
			color: grey;
		}
	}

	&:hover {
		filter: brightness(120%);
	}
	.form-button-loader {
		margin-bottom: 0 !important;
		width: 100%;
		max-height: 20px;
	}

	&:disabled {
		cursor: not-allowed;
		filter: brightness(70%);
	}
}
.form-button__error {
	font-family: "Source Sans Pro";
	color: red;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;
}
